

.layout {
    display:flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--bf-color-primary);

    main {
        flex-grow:1;
        overflow-y:auto;
        height:100px;
    }

    header {
        background-color: var(--bf-color-primary);
        color: #fff;
        padding: 10px;
        text-align: center;
    }

    footer{
        background-color: var(--bf-color-primary);
        color: #fff;
        padding: 10px;
        text-align: center;
        height: 50px;
    }
}