

.layout {
    footer{
        background-color: var(--bf-color-primary);
        color: #fff;
        padding: 10px;
        text-align: center;
        height: 50px;
    }
}