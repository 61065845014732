@import 'variables.scss';
@import 'react-date-range/dist/styles.css'; // main style file
@import 'react-date-range/dist/theme/default.css'; // theme css file

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


button.default {
  background: #000;
  border-radius: 10px;
  color:#FFF;
  width: 100%;
  padding: 12px 10px;
  font-weight:bold;
  font-size:16px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}