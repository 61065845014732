.filter {
    background: rgba(0, 0, 0, .5);
    border-radius: 10px;
    padding:0 16px;

    button {
        color:#FFF;
        min-height: 40px !important;
        
        &.Mui-selected {
            color:#FFF;
            border-bottom-width: 5px;
            font-weight: bold;
        }
    }
}
