.header {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: env(safe-area-inset-top, 0px);
    
    .page-title {
        flex-grow: 1;
        font-size: 1rem;
        font-weight: bold;
        text-transform: capitalize;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}