.leader {
    background: #f4f5f8;
    width: 100%;
    display:flex;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;

    .leader-info {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
        text-align: center;
        position: absolute;
        top: 50px;
        width: 100%;

        .name {
            font-size: 0.85rem;
            font-weight: bold;
            color:#FFF;
        }

        .score {
            font-size: 0.8rem;
            color: #fafafa;
        }
    }

    .avatar {
        width: 75px;
        height: 75px;
        border-radius: 50%;
        border: 5px solid #f4f5f8;
        position: absolute;
        top:-50px;
        left: 50%;
        transform: translateX(-50%);
    }

    &.first {
        background: rgba(0,0,0,0.3);
        height: 140px;

        &::before {
            position: absolute;
            content: "";
            background: url(../assets/images/king.svg) no-repeat;
            width: 50px;
            height: 50px;
            background-size: cover;
            top: -100px;
            left: 50%;
            transform: translateX(-50%);
        }

        &::after {
            position: absolute;
            content: "1";
            width: 20px;
            height: 20px;
            background-size: cover;
            left: calc(50% - 10px);
            top:22px;
            text-align: center;
            z-index: 100;
            border-radius:50%;
            background-color: #FFAA00;
            color:#FFF;
            font-size:0.85rem;
            font-weight: bold;
            display:flex;
            justify-content: center;
            align-items: center;
        }

        .avatar {
            // gold
            border-color: #FFAA00;
        }
    }

    &.second {
        background: rgba(0,0,0,0.5);
        border-top-right-radius: 0;
        height: 100px;
        border-bottom-left-radius: 10px;

        &::after {
            position: absolute;
            content: "2";
            width: 20px;
            height: 20px;
            background-size: cover;
            left: calc(50% - 10px);
            top:22px;
            text-align: center;
            z-index: 100;
            border-radius:50%;
            background-color: #009BD6;
            color:#FFF;
            font-size:0.85rem;
            font-weight: bold;
            display:flex;
            justify-content: center;
            align-items: center;
        }

        .avatar {
            border-color: #009BD6;
        }
    }

    &.third {
        background: rgba(0,0,0,0.5);
        height: 100px;
        border-top-left-radius: 0;
        border-bottom-right-radius: 10px;

        &::after {
            position: absolute;
            content: "3";
            width: 20px;
            height: 20px;
            background-size: cover;
            left: calc(50% - 10px);
            top:22px;
            text-align: center;
            z-index: 100;
            border-radius:50%;
            background-color: #00D95F;
            color:#FFF;
            font-size:0.85rem;
            font-weight: bold;
            display:flex;
            justify-content: center;
            align-items: center;
        }

        .avatar {
            border-color: #00D95F;
        }
    }

    
}