@font-face { font-family: "Rubik"; src: url("./assets/fonts/rubik.ttf"); }
@font-face { font-family: "Chivo"; src: url("./assets/fonts/Chivo.ttf"); }


/** Ionic CSS Variables **/
:root {
  /** primary **/
  --bf-color-primary: #4462a9;
  --bf-color-primary-rgb: 63, 76, 189;
  --bf-color-primary-contrast: #ffffff;
  --bf-color-primary-contrast-rgb: 255, 255, 255;
  --bf-color-primary-shade: #2634b3;
  --bf-color-primary-tint: #a3a8cf;

  /** secondary **/
  --bf-color-secondary: #D96D5E;
  --bf-color-secondary-rgb: 12, 209, 232;
  --bf-color-secondary-contrast: #ffffff;
  --bf-color-secondary-contrast-rgb: 255, 255, 255;
  --bf-color-secondary-shade: rgb(175, 75, 61);
  --bf-color-secondary-tint: rgb(241, 144, 131);

  /** tertiary **/
  --bf-color-tertiary: #403B69;
  --bf-color-tertiary-rgb: 112, 68, 255;
  --bf-color-tertiary-contrast: #ffffff;
  --bf-color-tertiary-contrast-rgb: 255, 255, 255;
  --bf-color-tertiary-shade: rgb(49, 44, 85);
  --bf-color-tertiary-tint: rgb(92, 86, 133);

  /** success **/
  --bf-color-success: #10dc60;
  --bf-color-success-rgb: 16, 220, 96;
  --bf-color-success-contrast: #ffffff;
  --bf-color-success-contrast-rgb: 255, 255, 255;
  --bf-color-success-shade: #0ec254;
  --bf-color-success-tint: #28e070;

  /** warning **/
  --bf-color-warning: #ffce00;
  --bf-color-warning-rgb: 255, 206, 0;
  --bf-color-warning-contrast: #ffffff;
  --bf-color-warning-contrast-rgb: 255, 255, 255;
  --bf-color-warning-shade: #e0b500;
  --bf-color-warning-tint: #ffd31a;

  /** danger **/
  --bf-color-danger: #f04141;
  --bf-color-danger-rgb: 245, 61, 61;
  --bf-color-danger-contrast: #ffffff;
  --bf-color-danger-contrast-rgb: 255, 255, 255;
  --bf-color-danger-shade: #d33939;
  --bf-color-danger-tint: #f25454;

  /** dark **/
  --bf-color-dark: #120F26;
  --bf-color-dark-rgb: 34, 34, 34;
  --bf-color-dark-contrast: #ffffff;
  --bf-color-dark-contrast-rgb: 255, 255, 255;
  --bf-color-dark-shade: #1e2023;
  --bf-color-dark-tint: #383a3e;

  /** medium **/
  --bf-color-medium: #7e7e7e;
  --bf-color-medium-rgb: 152, 154, 162;
  --bf-color-medium-contrast: #ffffff;
  --bf-color-medium-contrast-rgb: 255, 255, 255;
  --bf-color-medium-shade: #86888f;
  --bf-color-medium-tint: #a2a4ab;

  /** light **/
  --bf-color-light: #f4f5f8;
  --bf-color-light-rgb: 244, 244, 244;
  --bf-color-light-contrast: #000000;
  --bf-color-light-contrast-rgb: 0, 0, 0;
  --bf-color-light-shade: #d7d8da;
  --bf-color-light-tint: #f5f6f9;


  --bullet-background: #000;

  --bg: #FFF;
  --bg-secondary: #ecf1f5;
  --bg-secondary-rgb: 236, 241, 245;

  --ion-default-font: "Lato";
  --ion-font-family: "Lato";
}
