.list-profile-item {
    margin-bottom: 10px;

    .card-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        padding-bottom: 16px !important;

        .card-content {
            flex-grow: 1
        }

    }
}

